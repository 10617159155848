import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NextPrevNav } from "./NextPrevNav";
import { useSwipeable } from "react-swipeable";
import { prevNextChapterDisplays } from "../utils";
import { CallToAction } from "./CallToAction";
import { PURCHASE_URL } from "../constants";

function contentForChapterId(content, chapterId) {
  if (Object.keys(content).includes(chapterId)) return true
  return false
}

export function Chapter() {
  const [content, setContent] = React.useState({})
  const [fetchError, setFetchError] = React.useState(null)
  const navigate = useNavigate();

  const DEFAULT_CHAPTER_ID = 1

  let { chapterId } = useParams()
  const chapterCount = content?.chapterCount;
  const isLastChapter = chapterCount == chapterId
  const cta_text = isLastChapter ? "Buy Now To Continue Reading" : "Buy Now";

  if (typeof (mixpanel) !== "undefined") {
    mixpanel.track(`view-chapter-${chapterId}`, {
      'exampleKey': 'exampleValue'
    })
  }

  function swipeLeftHandler(eventData) {
    const { right } = prevNextChapterDisplays(chapterId, chapterCount)
    if (right) {
      navigate(`/chapters/${right}`)
    }
  }

  function swipeRightHandler(eventData) {
    const { left } = prevNextChapterDisplays(chapterId, chapterCount)
    if (left) {
      navigate(`/chapters/${left}`)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => swipeLeftHandler(eventData),
    onSwipedRight: (eventData) => swipeRightHandler(eventData),
  });

  if (!contentForChapterId(content, chapterId)) {
    if (!fetchError) {
      const url = `${BASE_URL}/api/v1/chapters/${chapterId || DEFAULT_CHAPTER_ID}`;
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        }
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === 404) {
            setFetchError("Resource not found")
            return
          }
          setContent({ ...content, ...data })
        })
        .catch(error => {
          setFetchError(error)
        });

      return (
        <div className="main-content">
          Loading...
        </div>
      )
    }

    return (
      <div className="main-content">
        There was an error - try reloading the page.
      </div>
    )

  }

  return (
    <div {...handlers} >
      <div className="main-content" dangerouslySetInnerHTML={{ __html: content[chapterId] }}>
      </div>
      <CallToAction url={PURCHASE_URL} text={cta_text} />
      <NextPrevNav currentChapter={chapterId} finalChapterIdx={chapterCount} />
    </div>


  )

}