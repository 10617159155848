import React from "react";
import { CallToAction } from "./CallToAction";
import { DOMAIN_URL, PURCHASE_URL } from "../constants";

export function Home() {
  return (
    <div id="home" >
      <div id="banner" className="padding-top-4em" >
        <CallToAction url={`${DOMAIN_URL}/chapters/1`} text="Start Reading" newTab={false} />
        <div className="spacer" />
        <CallToAction url={PURCHASE_URL} text="Buy Now" />
      </div>
    </div>
  )
}