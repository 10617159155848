import React from "react";

export const CallToAction = ({ url, text, newTab = true }) => {

  const urlOpts = `noreferrer${newTab ? ',noopener' : ''}`

  const handleClick = () => {
    console.log("handleClick:", mixpanel)
    if (typeof (mixpanel) !== "undefined") {
      console.log("mixpanel on handleclick")
      mixpanel.track(`cta-clicked`, {
        'exampleKey': 'exampleValue'
      })
    }

    window.open(url, '_blank', urlOpts);
  };

  return (
    <button className="cta"
      onClick={handleClick}
    >
      {text}
    </button>
  );
};
